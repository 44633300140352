<template>
  <ExternalLink style="margin-top: 20px;" :href="link">
    <template #prepend>
      <AppIcon name="info-circle-big" size="18px" fill="var(--color-black-04)" />
    </template>
    <slot />
  </ExternalLink>
</template>

<script setup>
import ExternalLink from '@/components/ExternalLink.vue';

const link = `https://${process.env.VUE_APP_DOCS_URL}/home/merchant-guide/connecting-your-wallet`;
</script>
