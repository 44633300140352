<template>
  <div class="coins">
    <SelectCoinItem
      v-for="coin in coinsList"
      :key="coin.value"
      :name="coin.name"
      :value="coin.value"
      :icon="coin.icon"
      :is-active="coin.isActive"
      @toggle="onToggle"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, ref, watch } from 'vue';

import { useTemporaryStore } from '@/store';
import { fullCoinsArray } from '@/common/data';

import SelectCoinItem from './SelectCoinItem.vue';

const coinsList = ref(fullCoinsArray.filter((coin) => coin.isPrimary));

const { importWallets, store } = useTemporaryStore();

const updateActiveState = () => {
  coinsList.value = coinsList.value.map((coin) => ({
    ...coin,
    isActive: store.importWallets.includes(coin.value),
  }));
};

const onToggle = (value, isActive) => {
  store.updateImportWallets(value, isActive);
  updateActiveState();
};

onBeforeMount(() => {
  updateActiveState();
});

watch(importWallets, () => {
  updateActiveState();
});
</script>

<style lang="scss" scoped>
.coins {
  display: grid;
  grid-template-columns: repeat(4, 160px);
  grid-auto-rows: 1fr;
  grid-gap: 20px 15px;
  margin-bottom: 40px;
}
</style>
