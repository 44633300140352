<template>
  <div class="paste-button">
    <AppIcon name="copy" size="18px" />
    <AppText variant="div">
      {{ t('merchantWallet.import.importKeys.label.paste') }}
    </AppText>
  </div>
</template>

<script>
export default {
  name: 'PasteButton',
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.paste-button {
  cursor: pointer;
  height: 28px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  @include transition-base('background-color');

  :deep(.text) {
    color: rgba(0, 0, 0, 1);
    opacity: 0.4;
    @include transition-base('opacity');
  }

  :deep(.icon) {
    opacity: 0.4;
    @include transition-base('opacity');
  }

  &:hover {
    background-color: var(--color-black-003);

    :deep(.text) {
      opacity: 1;
    }

    :deep(.icon) {
      opacity: 1;
    }
  }
}
</style>
