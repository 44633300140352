<template>
  <TransitionWrapper>
    <div class="wallet-setup d-flex">
      <FTabs
        v-model="currentTab"
        :options="tabs"
        tab-position="left"
        disable-tab-change
        class="wallet-tabs"
        :class="{ start: currentTab === 'start' }"
      >
        <template #label="{ item }">
          <template v-if="item.value">
            {{ t(`merchantWallet.tabs.title.${item.value}`) }}
          </template>
        </template>
      </FTabs>

      <div class="tab-wrapper">
        <SetupStart
          v-if="currentTab === 'start'"
          @start-generation-setup="onGenerate"
          @start-import-setup="onImport"
        />
        <template v-if="isWalletGenerate">
          <FamilySelectionStep
            v-if="currentTab === 'coinSelection'"
            @back="onReset"
            @next="currentTab = 'password'"
          />
          <PasswordStep
            v-if="currentTab === 'password'"
            @back="currentTab = 'coinSelection'"
            @next="currentTab = 'seed'"
          />
          <SeedStep
            v-if="currentTab === 'seed'"
            @next="currentTab = 'verification'"
          />
          <VerificationStep
            v-if="currentTab === 'verification'"
            @back="currentTab = 'seed'"
            @validate="onWalletSetup"
          />
          <FinalStep
            v-if="currentTab === 'finalize'"
            @finish="onWalletFinish"
          >
            {{ t('merchantWallet.generate.final.description') }}
          </FinalStep>
        </template>
        <template v-if="isWalletImport">
          <SelectCoinsStep
            v-if="currentTab === 'selectCoins'"
            @back="onReset"
            @next="prepareImportCoins"
          />
          <ImportKeys
            v-if="currentTab === 'keys'"
            :selected-coins="coinsToImport"
            @back="currentTab = 'selectCoins'"
            @next="currentTab = 'finalize'"
          />
          <FinalStep
            v-if="currentTab === 'finalize'"
            @finish="onWalletFinish"
          >
            {{ t('merchantWallet.import.final.description') }}
          </FinalStep>
        </template>
      </div>
    </div>
  </TransitionWrapper>
</template>

<script setup>
import {
  computed, onBeforeMount, onBeforeUnmount, ref,
} from 'vue';

import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { emitter, useBus } from '@/composables/useBus';
import { usePageLeaving } from '@/composables/usePageLeaving';
import { fullCoinsArray } from '@/common/data';
import { walletConnect, fetchWalletData } from '@/api/merchantWallet';

/* eslint-disable import/order */
import SetupStart from './WalletCommon/SetupStart.vue';
import FinalStep from './WalletCommon/FinalStep.vue';

import FamilySelectionStep from './WalletGenerate/FamilySelectionStep.vue';
import PasswordStep from './WalletGenerate/PasswordStep.vue';
import SeedStep from './WalletGenerate/SeedStep.vue';
import VerificationStep from './WalletGenerate/VerificationStep.vue';

import SelectCoinsStep from './WalletImport/SelectCoinsStep';
import ImportKeys from './WalletImport/ImportKeys';

import { useWalletSetup } from './useWalletSetup';
import { router } from '@/router';

emitter.emit('page-title', ['sidebar.wallet.setupWallet', '']);

const { generatePublicKeys, clearState, currentTab } = useWalletSetup();

currentTab.value = 'start';

const importTabs = [
  { value: 'start' },
  { value: 'selectCoins' },
  { value: 'keys' },
  { value: 'finalize' },
];

const genTabs = [
  { value: 'start' },
  { value: 'coinSelection' },
  { value: 'password' },
  { value: 'seed' },
  { value: 'verification' },
  { value: 'finalize' },
];

const tabs = computed(() => {
  if (isWalletImport.value && currentTab.value !== 'start') {
    return importTabs;
  }

  if (isWalletGenerate.value && currentTab.value !== 'start') {
    return genTabs;
  }

  return [{ value: 'start' }, {}, {}, {}, {}];
});

const onWalletSetup = async () => {
  const publicKeys = await generatePublicKeys();
  const { isSuccess } = await walletConnect({ ...publicKeys, imported: false });

  if (isSuccess) {
    currentTab.value = 'finalize';
  }
};

const onReset = () => {
  emitter.emit('page-title', ['sidebar.wallet.setupWallet', '']);
  clearState();
  coinsToImport.value = {};
  currentTab.value = 'start';
};

const { emitHandler } = useBus();

emitHandler('reset-wallet-setup', () => {
  onReset();
  router.push('/');
});

const onWalletFinish = async () => {
  await fetchWalletData({ isFreshResponse: true });
  router.push('/wallet');
};

onBeforeUnmount(() => {
  clearState();
});

onBeforeMount(() => {
  onReset();
});

const isWalletGenerate = ref(false);
const isWalletImport = ref(false);

const onGenerate = () => {
  emitter.emit('page-title', ['sidebar.wallet.setupWallet', 'merchantWallet.label.generateWallet']);
  currentTab.value = 'coinSelection';
  isWalletImport.value = false;
  isWalletGenerate.value = true;
};

const onImport = () => {
  emitter.emit('page-title', ['sidebar.wallet.setupWallet', 'merchantWallet.label.importKeys']);
  currentTab.value = 'selectCoins';
  isWalletGenerate.value = false;
  isWalletImport.value = true;
};

const coinsToImport = ref({});

const prepareImportCoins = (selectedCoins) => {
  currentTab.value = 'keys';
  coinsToImport.value = {};

  selectedCoins.forEach((coin) => {
    if (coin === 'TRC-20') {
      coinsToImport.value[coin] = fullCoinsArray.find((item) => item.value === 'TRON_USDT');
    } else if (coin === 'BEP-20') {
      coinsToImport.value[coin] = fullCoinsArray.find((item) => item.value === 'BSC_FDUSD');
    } else {
      coinsToImport.value[coin] = fullCoinsArray.find((item) => item.value === coin);
    }
  });
};

usePageLeaving({
  watchedRef: currentTab,
  condition: (val) => !['finalize', 'start'].includes(val.value),
  initialValue: 'start',
  callback: () => {
    setTimeout(() => {
      currentTab.value = 'start';
    }, 200);
  },
});
</script>

<style scoped lang="scss">
.wallet-setup {
  padding: 0 40px;
  height: 100%;
}

.wallet-tabs {
  min-height: calc(100vh - 80px);
  padding-top: 30px;
  padding-right: 20px;
  border-right: 1px solid var(--color-black-01);

  width: 210px;

  &.start {
    :deep(.custom-label) {
      cursor: default;
    }
    :deep(.custom-label:not(.is-custom-active)) {
      width: 82px;
      height: 8px;
      border-radius: 40px;
      background-color: var(--color-E6E6E6);
      color: transparent;
      @include transition-base('color, background-color');
    }
  }
}

.tab-wrapper {
  padding: 40px;
}
</style>
