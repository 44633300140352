<template>
  <div class="input-item" :class="[coin.family && coin.value !== 'ETH_ETH' ? 'secondary' : 'primary']">
    <div class="d-flex align-items-center" style="height: 44px;" :style="['TRC-20', 'BEP-20'].includes(coin.family) ? 'gap: 8px' : 'gap: 16px'">
      <AppIcon :name="coin.icon" :size="coin.family && coin.value !== 'ETH_ETH' ? '18px' : '28px'" is-img-tag />
      <div class="d-flex flex-column justify-content-center">
        <AppText
          variant="div"
          :size="coin.family && coin.value !== 'ETH_ETH' ? '12px' : '15px'"
          :opacity="coin.family && coin.value !== 'ETH_ETH' ? '0.6' : '1'"
          :line-height="1"
          class="font-medium"
        >
          {{ coin.name }} {{ coin.family && coin.value !== 'ETH_ETH' ? `(${coin.family})` : '' }}
        </AppText>
        <AppText
          v-if="type"
          variant="div"
          size="11px"
          mt="4px"
          :opacity="0.4"
          :line-height="1"
          class="font-medium"
        >
          {{ type }}
        </AppText>
      </div>
    </div>
    <div v-if="!coin.family || coin.value === 'ETH_ETH'" />
    <div>
      <KeyInput
        :coin="coin.value"
        :prefix="prefix"
        :error="isError ? t('merchantWallet.import.importKeys.label.invalidInput') : ''"
        :hint="hint"
        @update="(coin, key) => onUpdate(coin, key)"
        @clear="onClear"
      />
    </div>
  </div>
</template>

<script setup>
import KeyInput from './KeyInput.vue';

defineProps({
  coin: {
    type: Object,
    default: () => {},
  },
  type: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update', 'clear']);

const onUpdate = (coin, key) => {
  emit('update', coin, key);
};
const onClear = () => {
  emit('clear');
};
</script>

<style lang="scss" scoped>
.input-item {
  display: grid;

  &.primary {
    grid-template-columns: 110px 40px 600px;
  }

  &.secondary {
    grid-template-columns: 1fr;
  }
}
</style>
