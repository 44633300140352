<template>
  <div class="item" :class="{ active: isActive }" @click="onToggle">
    <AppCheckbox type="round" :model-value="isActive" />

    <AppIcon :name="icon" size="36px" is-img-tag />
    <AppText size="14px" :line-height="1.5" class="font-medium">
      {{ name }}
    </AppText>
  </div>
</template>

<script setup>
import AppCheckbox from '@/components/AppCheckbox.vue';

const emit = defineEmits(['toggle']);

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
});

const onToggle = () => {
  emit('toggle', props.value, !props.isActive);
};
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
  width: 160px;
  height: 102px;
  border-radius: 8px;
  border: 1px solid var(--color-black-01);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover:not(.active) {
    border-color: var(--color-primary-05);

    :deep(.app-checkbox .app-checkbox-control) {
      border-color: var(--color-primary-05);
    }
  }

  &.active {
    border-color: var(--color-primary);
    background-color: var(--color-FBF3EC);
    @include transition-base('background-color, border-color');
  }

  :deep(.app-checkbox) {
    position: absolute;
    top: -6px;
    right: -6px;
    z-index: 1;
  }
}
</style>
