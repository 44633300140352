<template>
  <StepWrapper width="900px">
    <template #prepend>
      <BackButton @click="$emit('back')" />
    </template>
    <template #title>
      {{ t('merchantWallet.import.importKeys.title') }}
    </template>

    <template #description>
      <AppText size="14px" :line-height="1.5" variant="div" style="padding-right: 100px;">
        {{ t('merchantWallet.import.importKeys.description') }}
      </AppText>
    </template>

    <template #extra>
      <AboutImportWalletsLink>
        {{ t('merchantWallet.import.importKeys.label.learnMore') }}
      </AboutImportWalletsLink>
    </template>

    <div class="keys">
      <KeyItem
        v-if="selectedCoins.BTC"
        :coin="selectedCoins.BTC"
        type="BIP49"
        :is-error="apiErrors.BTC"
        :prefix="t('merchantWallet.import.importKeys.label.publicKey')"
        @update="(coin, key) => keys[coin] = key"
        @clear="apiErrors = {}"
      />

      <KeyItem
        v-if="selectedCoins.LTC"
        :coin="selectedCoins.LTC"
        type="BIP49"
        :is-error="apiErrors.LTC"
        :prefix="t('merchantWallet.import.importKeys.label.publicKey')"
        @update="(coin, key) => keys[coin] = key"
        @clear="apiErrors = {}"
      />

      <KeyItem
        v-if="selectedCoins.DASH"
        :coin="selectedCoins.DASH"
        type="BIP44"
        :is-error="apiErrors.DASH"
        :prefix="t('merchantWallet.import.importKeys.label.publicKey')"
        @update="(coin, key) => keys[coin] = key"
        @clear="apiErrors = {}"
      />

      <KeyItem
        v-if="selectedCoins.ETH_ETH"
        :coin="selectedCoins.ETH_ETH"
        :is-error="apiErrors.ETHEREUM"
        :hint="t('merchantWallet.import.importKeys.label.evmHint')"
        :prefix="t('merchantWallet.import.importKeys.label.evm')"
        @update="(coin, key) => keys[coin] = key"
        @clear="apiErrors = {}"
      />

      <div v-if="selectedCoins.XMR" class="item">
        <div class="d-flex align-items-center" style="height: 44px; gap: 16px; align-self: center;">
          <AppIcon :name="selectedCoins.XMR.icon" size="28px" is-img-tag />
          <div>
            <AppText variant="div" size="14px" class="font-medium">
              {{ selectedCoins.XMR.name }}
            </AppText>
          </div>
        </div>
        <div class="fig-wrap">
          <div class="fig" />
        </div>
        <div class="d-flex flex-column" style="gap: 16px;">
          <KeyInput
            :coin="selectedCoins.XMR.value"
            :error="apiErrors?.XMR?.prvVkey"
            :prefix="t('merchantWallet.import.importKeys.label.privateViewKey')"
            @update="(coin, key) => keys[coin].prvVkey = key"
          />
          <KeyInput
            :coin="selectedCoins.XMR.value"
            :error="apiErrors?.XMR?.pubVkey"
            :prefix="t('merchantWallet.import.importKeys.label.publicViewKey')"
            @update="(coin, key) => keys[coin].pubVkey = key"
          />
          <KeyInput
            :coin="selectedCoins.XMR.value"
            :error="apiErrors?.XMR?.pubSkey"
            :prefix="t('merchantWallet.import.importKeys.label.privateSpendKey')"
            @update="(coin, key) => keys[coin].pubSkey = key"
          />
        </div>
      </div>

      <div v-if="isExtraCoinsVisible" class="extra-coins">
        <AppText
          size="14px"
          :line-height="1"
          class="font-medium"
          variant="div"
          mb="8px"
        >
          {{ t('merchantWallet.import.importKeys.extraCoins.title') }}
        </AppText>
        <AppText
          size="13px"
          :line-height="1.5"
          variant="div"
          mb="12px"
          color="var(--color-black-04)"
        >
          {{ t('merchantWallet.import.importKeys.extraCoins.description') }}
        </AppText>

        <div style="border-bottom: 1px solid var(--color-black-01); width: 750px; margin-bottom: 10px;" />
        <KeyItem
          v-if="selectedCoins['TRC-20']"
          :coin="selectedCoins['TRC-20']"
          :prefix="t('merchantWallet.import.importKeys.label.tvm')"
          @update="(coin, key) => keys[coin] = key"
          @clear="apiErrors = {}"
        />
        <KeyItem
          v-if="selectedCoins['BEP-20']"
          :coin="selectedCoins['BEP-20']"
          :prefix="t('merchantWallet.import.importKeys.label.evm')"
          @update="(coin, key) => keys[coin] = key"
          @clear="apiErrors = {}"
        />
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between" style="max-width: 750px;">
      <FButton
        :disabled="readyToImport.icons.length + readyToImport.extraCoins.length !== totalImport"
        :loading="isLoading"
        @click="onNext"
      >
        {{ t('merchantWallet.import.importKeys.label.import') }} {{ readyToImport.icons.length + readyToImport.extraCoins.length }}/{{ totalImport }}
      </FButton>
      <div class="coins">
        <template v-if="readyToImport.icons.length">
          <AppIcon v-for="icon in readyToImport.icons" :key="icon" :name="icon" size="40px" />
        </template>
      </div>
      <DotSeparator v-if="readyToImport.icons.length && readyToImport.extraCoins.length" color="#cccccc" />
      <CoinIcons
        class="extra-coins-icons"
        :coins="readyToImport.extraCoins"
        size="40px"
      />
      <ResetButton />
    </div>
  </StepWrapper>
</template>

<script setup>
import { computed, ref } from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';

import { walletConnect } from '@/api/merchantWallet';

import CoinIcons from '../../components/CoinIcons.vue';
import BackButton from '../../components/BackButton.vue';
import ResetButton from '../../components/ResetButton.vue';
import StepWrapper from '../../components/StepWrapper.vue';

import AboutImportWalletsLink from './AboutImportWalletsLink.vue';
import KeyInput from './KeyInput.vue';
import KeyItem from './KeyItem.vue';

const emit = defineEmits(['next', 'back']);

const props = defineProps({
  selectedCoins: {
    type: Object,
    required: true,
  },
});

const keys = ref({
  BTC: null,
  LTC: null,
  DASH: null,
  ETHEREUM: null,
  XMR: {
    prvVkey: null,
    pubVkey: null,
    pubSkey: null,
  },
});

const apiErrors = ref({ XMR: {} });

const isLoading = ref(false);
const onNext = async () => {
  isLoading.value = true;
  const { isSuccess, error } = await walletConnect(readyToImport.value.wallets);
  if (isSuccess) {
    emit('next');
  } else {
    if (error?.data?.errors?.bip39Family) {
      error.data.errors.bip39Family.forEach((e) => {
        apiErrors.value[e.currency] = true;
      });
    }

    if (error?.data?.errors?.xmrFamily) {
      error.data.errors.xmrFamily.forEach((e) => {
        const key = Object.keys(e)[0];
        apiErrors.value.XMR[key] = e[key];
      });
    }
  }
  isLoading.value = false;
};

const readyToImport = computed(() => {
  const wallets = {
    bip39Family: [],
    xmrFamily: undefined,
    imported: true,
  };
  const icons = [];
  const extraCoins = [];

  Object.entries(keys.value).forEach((coin) => {
    const [key, value] = coin;
    if (!value || key === 'XMR') {
      return;
    }

    if (key === 'ETH_ETH') {
      icons.push(props.selectedCoins[key]?.icon);
      wallets.bip39Family.push({ coin: 'ETHEREUM', primaryAddress: keys.value[key] });
      return;
    }
    if (key === 'TRON_USDT') {
      extraCoins.push('TRON');
      wallets.bip39Family.push({ coin: 'TRON', primaryAddress: keys.value[key] });
      return;
    }
    if (key === 'BSC_FDUSD') {
      extraCoins.push('BSC');
      wallets.bip39Family.push({ coin: 'BSC', primaryAddress: keys.value[key] });
      return;
    }

    icons.push(props.selectedCoins[key]?.icon);
    wallets.bip39Family.push({ coin: key, pubKey: keys.value[key] });
  });

  if (!(Object.keys(keys.value.XMR).some((k) => keys.value.XMR[k] === null))) {
    wallets.xmrFamily = keys.value.XMR;
    icons.push(props.selectedCoins.XMR.icon);
  }

  return { wallets, icons, extraCoins };
});

const totalImport = computed(() => {
  if (Object.prototype.hasOwnProperty.call(props.selectedCoins, 'ERC-20')) {
    return Object.keys(props.selectedCoins).length - 1;
  }

  return Object.keys(props.selectedCoins).length;
});

const isExtraCoinsVisible = computed(() => Object.prototype.hasOwnProperty.call(props.selectedCoins, 'TRC-20') || Object.prototype.hasOwnProperty.call(props.selectedCoins, 'BEP-20'));
</script>

<style lang="scss" scoped>
.coins {
  margin-left: 20px;

  :deep(.icon) {
    border: 2px solid var(--color-white);
    margin-left: -10px;
    border-radius: 50%;
  }
}

.extra-coins {
  margin-top: 40px;

  :deep(.input-item) {
    max-width: 750px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.extra-coins-icons {
  flex-grow: 1;
  gap: 8px !important;
}

.keys {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 44px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--color-black-01);

  .item {
    display: grid;
    grid-template-columns: 110px 40px 600px;

    .fig-wrap {
      height: 100%;
      padding: 20px 0 20px 16px;

      .fig {
        width: 4px;
        height: 100%;
        border: 1px solid var(--color-black-01);
        border-right: none;
      }
    }
  }
}
</style>
