<template>
  <ActionForm
    :request-fields="requestFields"
    :validation-schema="validationSchema"
    @input="onInput"
    @validate="onConfirm"
    @error="onError"
  >
    <template #default="{ errors, action }">
      <FInput
        :id="inputId"
        ref="inputRef"
        v-model="requestFields.key"
        :validation-error="errors.key || { message: error }"
        class="key-input"
        :class="{ focused: isFocused }"
        :hint="hint"
        mask="numsAndChars"
        @input="onInput"
        @focus="isFocused = true"
        @blur="onBlur(action)"
        @keypress.enter="onBlur(action)"
      >
        <template v-if="prefix" #prefix>
          <AppText class="prefix">
            {{ prefix }}
          </AppText>
        </template>

        <template #suffix>
          <div class="d-flex align-items-center justify-content-center cursor-pointer" @mouseenter="isAppendHover = true" @mouseleave="isAppendHover = false">
            <transition name="show">
              <PasteButton v-if="!isKeyReady && (!isValidationError || requestFields.key.length === 0)" @click="onPaste" />
              <AppIcon v-else-if="isProcessing && !isValidationError" class="loader-animated" name="loader-animated" size="18px" />
              <AppIcon
                v-else-if="(isKeyReady && !isAppendHover) && !isValidationError"
                class="check"
                name="arrow-check-done"
                fill="var(--color-green)"
                size="18px"
              />
              <AppIcon
                v-else-if="(isKeyReady && isAppendHover) || isValidationError"
                class="close"
                name="x-close"
                size="18px"
                @click="onClear"
              />
            </transition>
          </div>
        </template>
      </FInput>
    </template>
  </ActionForm>
</template>

<script setup>
import {
  computed,
  onMounted, ref, watch,
} from 'vue';

import { ActionForm, coinKeyRule, fieldValidation } from '@/validation';

import PasteButton from './PasteButton.vue';

const emit = defineEmits(['update', 'clear']);

const props = defineProps({
  coin: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
});

const inputRef = ref(null);
const isFocused = ref(false);
const isProcessing = ref(false);
const isAppendHover = ref(false);

const onError = (errors) => {
  Object.assign(fieldsErrors.value, errors);
};

const {
  validationSchema,
  requestFields,
  fieldsErrors,
} = fieldValidation({ fieldName: 'key', rule: coinKeyRule(props.coin) });

const updateValue = () => {
  setTimeout(() => {
    if (requestFields.key && !fieldsErrors.value?.key?.length) {
      emit('update', props.coin, requestFields.key);
    } else {
      emit('update', props.coin, null);
    }
  }, 0);
};

const onPaste = () => {
  navigator.clipboard.readText().then((text) => {
    inputRef.value.$el.querySelector('.el-input__inner').focus();
    setTimeout(() => {
      requestFields.key = text;
      inputRef.value.$el.querySelector('.el-input__inner').blur();
      updateValue();
      onInput();
    }, 0);
  });
};

const onConfirm = () => {
  inputRef.value.$el.querySelector('.el-input__inner').blur();
  updateValue();
};

const onBlur = (action) => {
  isFocused.value = false;
  if (requestFields.key) {
    action();
  }
  updateValue();
};

const fakeKey = computed(() => (requestFields.key.length > 30 ? `${requestFields.key.substring(0, 30)}...` : requestFields.key));
const inputId = computed(() => `input-${props.coin.toLowerCase()}`);
const isValidationError = computed(() => Boolean(fieldsErrors.value?.key?.length || props.error));

const isKeyReady = computed(() => !isFocused.value && !fieldsErrors.value?.key?.length && requestFields.key.length > 0);

watch(isKeyReady, () => {
  isProcessing.value = true;
  setTimeout(() => {
    isProcessing.value = false;
  }, 500);
});

const onInput = () => {
  inputRef.value.$el.querySelector('.fake-key').innerText = fakeKey.value;
  if (fieldsErrors.value?.key?.length) {
    fieldsErrors.value = {};
  }
};

const onClear = () => {
  requestFields.key = '';
  onInput();
  updateValue();
  emit('clear');
};

onMounted(() => {
  inputRef.value.$el.querySelector('.el-input__prefix').setAttribute('id', inputId.value);
  const el = document.createElement('div');
  el.setAttribute('class', 'fake-key');
  el.textContent = fakeKey.value;
  inputRef.value.$el.querySelector('.el-input__prefix').appendChild(el);
});
</script>

<style lang="scss" scoped>
.key-input {
  :deep(.el-input__prefix) {
    position: relative;
    .fake-key {
      position: absolute;
      left: calc(100% + 12px);
      color: transparent;
      font-size: 13px;
      @include transition-base('color');
    }
  }

  &:not(.focused) {
    :deep(.el-input__prefix .fake-key) {
      color: rgba(0, 0, 0, 1);
    }
  }

  :deep(.el-input__inner:not(:focus)) {
    color: transparent;
    @include transition-base('color');
  }

  .prefix {
    border-right: 1px solid var(--color-D9D9D9);
    padding-right: 16px;
  }

  .loader-animated {
    :deep(path) {
      fill: var(--color-green);
    }
  }

  .check {
    :deep(g) {
      opacity: 1;
      path {
        stroke: var(--color-green);
      }
    }
  }
}
</style>
