<template>
  <StepWrapper width="900px">
    <template #prepend>
      <BackButton @click="$emit('back')" />
    </template>
    <template #title>
      {{ t('merchantWallet.import.selectCoins.title') }}
    </template>
    <template #description>
      {{ t('merchantWallet.import.selectCoins.description') }}
    </template>

    <SelectCoinItems />

    <AppText size="12px" class="font-medium" mb="20px" variant="div">
      {{ t('merchantWallet.import.selectCoins.thirdParty') }}
    </AppText>

    <ExtraCoins is-control />

    <div class="d-flex align-items-center justify-content-between" style="max-width: 685px">
      <FButton :disabled="!importWallets.length" @click="onNext">
        {{ t('common.continue') }}
      </FButton>
      <ResetButton />
    </div>
  </StepWrapper>
</template>

<script setup>
import { useTemporaryStore } from '@/store';

import ExtraCoins from '../../components/ExtraCoins.vue';
import BackButton from '../../components/BackButton.vue';
import ResetButton from '../../components/ResetButton.vue';
import StepWrapper from '../../components/StepWrapper.vue';

import SelectCoinItems from './SelectCoinItems.vue';

const { importWallets } = useTemporaryStore();

const emit = defineEmits(['next', 'back']);

const onNext = () => {
  emit('next', importWallets.value);
};
</script>
