<template>
  <TransitionWrapper>
    <WalletSetup />
  </TransitionWrapper>
</template>

<script>
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import WalletSetup from './WalletSetup.vue';

export default {
  name: 'WalletSetupWrapper',
  components: {
    TransitionWrapper,
    WalletSetup,
  },
};
</script>
